/*
|--------------------------------------------------------------------------
| Add Global/Shared Component Style In This File
| You'll Also Found Header & Footer Style In This File
| Last Update: 11th Dec 2019 
| Author: Whiz-Solutions
|--------------------------------------------------------------------------
*/ 
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap');
/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap');

.fa{font-family:FontAwesome!important;}
input[type="number"] {
  -moz-appearance: textfield;
}
.not-allowed-action:hover {
  cursor: no-drop;
}
button:focus {
  outline: none !important;
}
.Combined-Shape {
  border-radius: 0!important;
}
.btn {
  &:focus {
    box-shadow: none;
  }
}
// .navbar.swire-header {
//   padding: 15px 0 15px 0;
//   transition: 0.3s;
//   position: absolute;
//   width: 100%;
//   z-index: 11;

//   // background: black;

//   @media screen and (max-width: 991px) {
//     // padding: 15px 25px 15px 30px;
//     padding: 0;
//   }
//   @media screen and (max-width: 992px) {
//     overflow-y: auto;
//   }
//   &.navbar-drodown-active {
//     @media screen and (max-width: 991px) {
//       // background: black;
//       height: 100%;
//       justify-content: flex-start;
//       align-items: flex-start;
//     }
//   }
//   &.header-mobile-view {
//     position: fixed;
//     background: #00182C;
//   }
//   // &.header-bg {
//   //   background: black;
//   //   display: none !important;
//   // }
//   // &.search-result-header-bg-color {
//   //   background: black;
//   //   background: black;
//   //   transition: 0.3s;
//   //   display: none!important;
//   // }

//   .container-fluid {
//     @media screen and (min-width: 1024px) {
//       width: calc(100% - 8%);
//     }
//     @media screen and (max-width: 991px) {
//       padding: 12px 0;
//     }
//     .navbar-brand {
//       @media screen and (max-width: 991px) {
//         margin: auto;
//       }
//       img {
//         max-width: 200px;
//         width: 100%;
//         @media screen and (max-width: 991px) {
//           width: 40px;
//           position: absolute;
//           top: 15px;
//           left: 50%;
//           transform: translateX(-50%);
//         }
//       }
//     }
//     .navbar-toggler {
//       padding: 0px !important;
//       .navbar-toggler-icon {
//         padding-top: 3px;
//         display: flex !important;
//         img {
//           width: 28px !important;
//         }
//       }
//       &:focus {
//         outline: 0;
//         border: 0;
//       }
//       @media screen and (max-width: 991px) {
//         margin-right: 25px;
//       }
//     }
//     .navbar-contain {
//       @media screen and (max-width: 991px) {
//         background: #00182C;
//       }
//       .navbar-nav {
//         position: relative;
//         bottom: -5px;
//         @media screen and (max-width: 991px) {
//           padding: 40px 30px 30px 30px;
//         }
//         @media screen and (max-width: 767px) {
//           height: 100vh;
//           padding-bottom: 50px;
//           overflow-y: auto;
//         }
//         .nav-item {
//           position: relative;
//           margin-left: 40px;
//           @media screen and (max-width: 991px) {
//             position: relative;
//             margin-bottom: 25px;
//             margin-left: 0;
//             &:before {
//               content: "";
//               position: absolute;
//               top: 0;
//               left: 0;
//               width: 22px;
//               height: 4px;
//               opacity: 0.5;
//               background: white;
//               @media screen and (max-width: 767px) {
//                 height: 2px;
//               }
//             }
//             &.show {
//               &:before {
//                 background: #1FB2F8;
//               }
//               .nav-link {
//                 color: white;
//                 opacity: 1;
//                 font-size: 20px;
//                 padding-bottom: 0;
//                 @media screen and (max-width: 767px) {
//                   font-size: 14px;
//                 }
//               }
//             }
//           }
//           @media screen and (max-width: 767px) {
//             margin-bottom: 30px;
//           }
//           .nav-link {
//             color: white;
//             text-transform: uppercase;
//             position: relative;
//             font-weight: 500;
//             letter-spacing: 0.5;
//             font-size: 16px;
//             @media screen and (max-width: 991px) {
//               font-weight: 500;
//               letter-spacing: 0.5;
//               font-size: 16px;
//               opacity: 0.5;
//             }
//             @media screen and (max-width: 767px) {
//               font-size: 14px;
//               font-weight: 500;
//               padding-bottom: 0;
//               opacity: 0.5;
//             }

//             &:hover {
//               text-decoration: none;
//             }
//             &:after {
//               display: none;
//             }
//             &:focus {
//               outline: 0;
//             }
//             .header-count {
//               position: absolute;
//               width: 24px;
//               height: 24px;
//               background: red;
//               border-radius: 50px;
//               font-size: 13px;
//               text-align: center;
//               color: white;
//               line-height: 24px;
//               font-weight: 600;
//               top: 0;
//               right: -26px;
//               z-index: 1;
//             }
//           }
//           .dropdown-menu {
//             padding: 0;
//             background: transparent;
//             @media screen and (max-width: 991px) {
//               border: 0 !important;
//             }
//             .dropdown-item {
//               padding-left: 0;
//               color: white;
//               font-weight: 400;
//               @media screen and (max-width: 991px) {
//                 font-size: 16px;
//                 opacity: 0.5;
//               }
//               @media screen and (max-width: 767px) {
//                 font-size: 14px;
//                 opacity: 0.5;
//               }
//               &:hover {
//                 background: transparent;
//               }
//             }
//           }
//           &:last-child {
//             .nav-link {
//               padding-right: 0;
//             }
//           }
//         }
//       }
//       &.tab-middle-style {
//         @media screen and (min-width: 991px) and (max-width: 1024px) {
//           position: absolute;
//           right: 60px;
//           top: 55px;
//           background: white;
//           width: 160px;
//           padding: 10px;
//           border-bottom: 5px #2475e9 solid;
//           box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
//             0 3px 4px 0 rgba(0, 0, 0, 0.14);
//           display: none !important;
//           &:before {
//             width: 12px;
//             height: 12px;
//             content: "";
//             position: absolute;
//             top: -6px;
//             right: 6px;
//             box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
//               0 3px 4px 0 rgba(0, 0, 0, 0.14);
//             background: white;
//             transform: rotate(45deg);
//             z-index: -1;
//           }
//           &:after {
//             width: 12px;
//             height: 12px;
//             content: "";
//             position: absolute;
//             top: -6px;
//             right: 6px;
//             background: white;
//             transform: rotate(45deg);
//             z-index: 1;
//           }
//           &.show {
//             display: flex !important;
//           }
//           .navbar-nav {
//             flex-direction: column;
//             max-width: 160px;
//             width: 100%;
//             .nav-item {
//               margin-left: 0;
//               &:before {
//                 content: "";
//                 position: absolute;
//                 top: 4px;
//                 left: 0;
//                 width: 22px;
//                 height: 2.5px;
//                 opacity: 1;
//                 background: #9d9d9d;
//               }
//               a {
//                 color: #9d9d9d;
//                 font-size: 14px;
//                 padding-left: 0;
//                 .header-count {
//                   right: 0;
//                   top: 10px;
//                 }
//               }
//               &.tablet-active {
//                 &:before {
//                   background: #2475e9;
//                 }
//                 a {
//                   color: #2475e9 !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   &.header-bg-img {
//     background: url("assets/app/media/img/logos/logo-bg-1200@3x.png");
//     background-size: cover;
//     padding: 15px 0 40px 0;
//     background-position-y: -16px;
//     background-repeat: no-repeat;
//     background-position-x: -175px;
//     @media screen and (max-width: 1200px) {
//       background-position-x: -196px;
//       background-position-y: -15px;
//     }
//     @media screen and (max-width: 991px) {
//       // background: #a52a2a;
//       background: transparent;
//       // background: linear-gradient(to bottom,  rgb(137,14,21) 0%,rgb(216,56,56) 100%);
//       padding: 0px 0px;
//     }
//     .navbar-brand {
//       // padding-left: 15px;
//       transition: 0.3s;
//       img {
//         max-width: 200px;
//         transition: 0.3s;
//       }
//     }
//     .navbar-toggler {
//       margin-right: 15px;
//       @media screen and (min-width: 992px) and (max-width: 1024px) {
//         margin-right: -3px;
//       }
//       .navbar-toggler-icon {
//         @media screen and (max-width: 991px) {
//           padding-top: 3px;
//           display: flex !important;
//           img {
//             width: 28px !important;
//           }
//           svg{
//             g{
//               fill: #00182C;
//               stroke: #00182C;
//               path{
//                 fill: #00182C;
//                 stroke: #00182C;

//               }
//             }
//           }
//         }
//         @media screen and (min-width: 992px) and (max-width: 1024px) {
//           svg {
//             g {
//               fill: #00182c;
//               stroke: #00182c;
//               path {
//                 fill: #00182c;
//                 stroke: #00182c;
//               }
//             }
//           }
//         }
//       }
//     }
//     .navbar-contain {
//       .navbar-nav {
//         .nav-item {
//           @media (min-width: 1025px) and (max-width: 1270px) {
//             margin-left: 15px;
//           }
//           .nav-link {
//             color: black !important;
//             font-weight: 500;
//             font-size: 16px;
//             @media (min-width: 1025px) and (max-width: 1270px) {
//               font-size: 16px;
//             }
//             @media screen and (max-width: 991px) {
//               color: white !important;
//               opacity: 1;
//             }
//           }
//         }
//       }
//     }
//     &.header-mobile-view{
//       @media screen and (max-width: 991px) {
//         background: #00182C;
//       }
//       @media screen and (max-width: 991px) {
//         .navbar-toggler-icon {
//           padding-top: 3px;
//           display: flex !important;
//           img {
//             width: 28px !important;
//           }
//           svg{
//             g{
//               fill: white;
//               stroke: white;
//               path{
//                 fill: white;
//                 stroke: white;

//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/
// .page-footer {
//   background-color: #555555;
//   padding: 40px 0px;
//   position: relative;
//   // z-index: 1; //Hide for Static Pages Arrow
//   @media screen and (max-width: 992px) {
//     position: relative;
//     // top:-60px;
//   }
//   @media screen and (max-width: 767px) {
//     position: relative;
//   }
//   .container-fluid {
//     @media screen and (min-width: 1024px) {
//       width: calc(100% - 8%) !important;
//     }
//     .first-block {
//       h5 {
//         font-size: 33px;
//         margin-bottom: 10px;
//         font-weight: bold;
//         @media screen and (max-width: 576px) {
//           font-size: 24px;
//         }
//       }
//       .next-big-heading {
//         font-size: 18px;
//         font-weight: 400;
//         @media screen and (max-width: 576px) {
//           font-size: 13px;
//         }
//       }
//       p {
//         font-size: 14px;
//         font-weight: bold;
//       }
//     }
//     .second-block {
//       padding-top: 5px;
//       .right-first-block {
//         padding-top: 20px;

//         @media screen and (max-width: 991px) {
//           margin-bottom: 10px;
//         }
//         @media screen and (max-width: 575px) {
//           padding-top: 0px;
//           padding-bottom: 20px;
//         }
//         &.last-first-block {
//           @media screen and (max-width: 575px) {
//             padding-bottom: 0px;
//           }
//         }
//         h5 {
//           font-size: 14px;
//           font-weight: bold;
//           position: relative;
//           margin-bottom: 5px;
//           @media screen and (max-width: 991px) {
//             font-size: 13px;
//             margin-bottom: 0;
//           }
//           &:before {
//             content: "";
//             position: absolute;
//             width: 22px;
//             height: 4px;
//             background: white;
//             left: 0;
//             top: -8px;
//             @media screen and (max-width: 991px) {
//               height: 2px;
//             }
//           }
//           &.connect-with-us {
//             margin-bottom: 0px;
//           }
//         }
//         ul {
//           margin-bottom: 0;
//           li {
//             line-height: 18px;
//             a {
//               font-size: 12px;
//               color: rgba(255, 255, 255, 0.65);
//               font-weight: 400;
//               &:hover {
//                 color: white;
//               }
//               &.email {
//                 text-decoration: underline;
//               }
//             }
//             &.phone-no {
//               font-size: 12px;
//               color: rgba(255, 255, 255, 0.65);
//               font-weight: 500;
//             }
//           }
//           &.address {
//             li {
//               font-size: 12px;
//               color: rgba(255, 255, 255, 0.65);
//               font-weight: 400;
//             }
//           }
//         }
//       }
//       .Swire-shipping-footer {
//         @media screen and (max-width: 575px) {
//           margin-top: 0px;
//         }
//         @media screen and (max-width: 991px) {
//           flex-direction: column-reverse;
//         }
//         margin-top: 20px;

//         .swire-footer-logo {
//           p {
//             margin-bottom: 6px;
//             img {
//               @media screen and (max-width: 991px) {
//                 width: 100%;
//               }
//             }
//             &.terms-privacy {
//               font-size: 10.5px;
//               color: white;
//               margin-bottom: 5px;
//               font-weight: 400;
//             }
//           }
//         }
//         .image-text {
//           img {
//             @media screen and (max-width: 767px) {
//               width: 100%;
//               max-width: 145px;
//             }
//           }
//           &.last-image-text {
//             img {
//               max-width: 100%;
//             }
//           }
//         }
//       }
//     }
//     .only-mobile {
//       .swire-footer-logo {
//         .image-text {
//           margin-bottom: 6px;
//           img {
//             width: 100%;
//             max-width: 575px;
//           }
//         }
//         .terms-privacy {
//           font-size: 10.5px;
//           color: #fff;
//           margin-bottom: 5px;
//           font-weight: 400;
//         }
//       }
//     }
//   }
// }

/*
|--------------------------------------------------------------------------
| Body
|--------------------------------------------------------------------------
*/

* {
  padding: 0;
  margin: 0;
}
body {
  color: darkgrey !important;
  font-family: "Lato", sans-serif !important;
  overflow-x: hidden;
  background-color: #f1f1f0!important;
}

// .swire-page-view {
//   height: calc(80vh + 84px);
// }

/*
|--------------------------------------------------------------------------
| Global Misc
|--------------------------------------------------------------------------
*/

.toast {
  opacity: 1 !important;
}

#toast-container > div {
  opacity: 1 !important;
}

.m-page-loader {
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);

  .m-blockui {
    z-index: 11;
    width: 310px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    span {
      font-size: 16px;
      font-weight: 500;
      color: white;
      display: block;
      padding-top: 10px;
    }
  }
}
//errors
.required-error {
  color: #cc0000;
}
.validation-errors {
  color: #6b6b6b;
  font-weight: 400;
  font-style: italic;
  padding-top: 5px;
  padding-left: 20px;
  .no-service-error {
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
}

.link-orange,
.link-orange:hover,
.link-orange:focus {
  color: #cc0000;
  font-weight: 400;
  cursor: pointer;
}

//Shipping Loader
.animation {
  position: relative;
  width: 100%;
  height: 100%;
}
.svg-div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -50px;
}
.boat {
  animation: 1.3s boatupdown infinite alternate ease-in;
}
.middle-wave {
  animation: 1.3s middlewave infinite alternate ease-in;
}
.bottom-wave {
  animation: 1.3s bottom-wave infinite alternate ease-in;
}

// Scroll Top Style Start Here
.back-to-top {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: fixed;
  bottom: 10%;
  right: 3%;
  z-index: 2;
  .fa {
    font-size: 40px;
    margin-right: 5px;
    position: relative;
    top: 5px;
  }
  svg {
    opacity: 0.6;
    width: 30px;
    height: 30px;
  } 

  &.level-trans{
    use{
      fill: white;
    } 
  }
}
 

// Scroll Top Style End Here
.container-accept-main-cookie{
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  top: 0;

.container-accept-cookie {
  position: fixed;
  bottom: 0;
  z-index: 99999999;
  background: white;
  width: 100% !important;
  padding: 0;
  animation: slideuper 0.6s ease-in;
  .accept-cookie-policy {
    width: calc(100% - 8%);
    margin: auto;
    align-items: center;
    .left-policy-heading {
      padding: 40px 0;
      @media screen and (max-width: 575px) {
        padding: 40px 0 20px 0;
      }
      h3 {
        color: #cc0000;
        font-size: 15px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        color: #555555;
        font-weight: 400;
      }
    }
    .right-both-btn {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      &:focus {
        box-shadow: none;
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
        text-align: center;
      }
      @media screen and (max-width: 345px) {
        display: flex;
       }
      .accept-cookie-btn {
        max-width: 117px;
        min-height: 39px;
        border: 0.9px solid#cc0000;
        border-radius: 0;
        color:#cc0000;
        margin:0;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1) {
          background: white;
          color: #cc0000;
          margin-right: 30px;
          &:hover {
           background: #cc0000;
          color: white;
          }
          &:focus {
            box-shadow: none;
          }

          @media screen and (max-width: 767px) {
            margin-right: 20px;
          }
        }
        &:nth-child(2) {
          &:hover {
          background:#cc0000;
          color: white;
          }
        }
        &.disable-acp-btn {
          background: white;
          color: #cc0000;
          border: 0.9px solid#cc0000;
        }
        &.enable-acp-btn {
          background:#cc0000;
          color: white;
        }
      }
      .accept-cncov {
        max-width: 117px;
        min-height: 39px;
        border: 0.9px solid#cc0000;
        border-radius: 0;
        color:#cc0000;
        margin:0;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(1) {
          margin-right: 30px;
          &:hover {
            background: #cc0000;
            color: white;
          }
          @media screen and (max-width: 767px) {
            margin-right: 20px;

          }
        }
        &:nth-child(2) {
          background: #cc0000;
          color: white;
          &:hover {
            background: white;
            color: #cc0000;
          }
          &:focus {
            box-shadow: none;
          }
        }
        &.disable-acp-btn {
          background: white;
          color: #cc0000;
          border: 0.9px solid#cc0000;
        }
      }
      @media screen and (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
  }
}
}
.container-cncov-main{
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  top: 0;
.container-cncov {
  position: fixed;
  bottom: 0;
  z-index: 99999999;
  background: white;
  width: 100% !important;
  padding: 0;
  animation: slideuper 0.6s ease-in;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  .accept-cncov-policy {
    width: calc(100% - 6%);
    margin: auto;
    align-items: center;
    .left-policy-heading {
      padding: 40px 0;
      display: inline-flex;
      @media screen and (max-width: 767px) {
        padding: 40px 0 20px 0;
      }
      h3 {
        color: #cc0000;
        font-size: 15px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        color: #555555;
        font-weight: 400;
      }
      .cncov-icon {
        margin-right: 10px;
      }
    }
    .right-both-btn {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
      &:focus {
        box-shadow: none;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
      .accept-cookie-btn {
        max-width: 117px;
        min-height: 39px;
        border: 0.9px solid#cc0000;
        border-radius: 0;
        color: #cc0000;
        margin: auto;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: 100%;

        &:nth-child(1) {
          background: #cc0000;
          color: white;
          margin-right: 30px;

          &:hover {
            // background: white;
            // color: #cc0000;
          }
          &:focus {
            box-shadow: none;
          }
          @media screen and (max-width: 767px) {
            margin-right: 20px;
          }
        }
        &:nth-child(2) {
          &:hover {
            background: #cc0000;
            color: white;
          }
        }
        &.disable-acp-btn {
          background: white;
          color: #cc0000;
          border: 0.9px solid#cc0000;
        }
      }
      .accept-cncov {
        max-width: 117px;
        border: 0.9px solid#cc0000;
        border-radius: 0;
        color: #cc0000;
        margin: auto;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: 100%;
        min-height: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        &:nth-child(1) {
          margin-right: 30px;

          &:hover {
            background: #cc0000;
            color: white;
          }
          @media screen and (max-width: 1024px) {
            margin-right: 20px;
          }
          @media screen and (max-width: 767px) {
            margin-right: 20px;
          }
        }
        &:nth-child(2) {
          background: #cc0000;
          color: white;

          &:hover {
            // background: white;
            // color: #cc0000;
          }
          &:focus {
            box-shadow: none;
          }
        }
        &.disable-acp-btn {
          background: white;
          color: #cc0000;
          border: 0.9px solid#cc0000;
        }
        @media screen and (max-width: 527px) {
          font-size: 13px;
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
  }
}
}
@media screen and (max-width: 767px) {
  .hideMobileMessage {
    display: none;
  }
}
.next-pager {
  position: absolute;
  li {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.6);
    margin-left: 10px;
    span {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    img {
      position: relative;
      top: 4px;
    }
  }
}
.jumper-page-link {
  min-height: 65px;
  position: relative;
  background: #00182c;
  @media screen and (max-width:991px) {
    margin-top: -3px;
  }
  &.fixed-jumer-link {
    min-height: auto !important;
    position: fixed !important;
    background: transparent !important;
    bottom: 0 !important;
    z-index: 9;
    width: 100%;
    height: 70px;

    &.hide-product-link {
      @media screen and (max-width: 1199px) {
        display: block;
        position: static !important;
        background: #00182c !important;
        margin-top: -2px;
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    li {
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.2;
      img {
        position: relative;
        top: 0px;
      }
      &:nth-child(1) {
        margin-right: 10px;
        img {
          transform: rotate(180deg);
        }
      }
      span {
        font-size: 30px;
        font-weight: 400;
        color: white;
        small {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    &.next-pager {
      position: absolute;
      right: 0;
      li {
        text-align: right;
      }
    }
    &.previous-pager {
      position: absolute;
      left: 0;
    }
  }
}

.download-file {
  cursor: pointer;
}

@-webkit-keyframes boatupdown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}
@-webkit-keyframes middlewave {
  0% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(-5px);
  }
}
@-webkit-keyframes bottom-wave {
  0% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(5px);
  }
}

// loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .desktop-none {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .mobile-none {
    display: none !important;
  }
}
// .form-control{
//   &::placeholder{
//     color:#c4c4c4!important;
//   }
// }
// .ng-select-container{
//   .ng-value-container{
//     .ng-value-label,.ng-input{
//       color: #c4c4c4;
//     }
//   }
// }

.error {
  &.form-control {
    &::placeholder {
      color: #cc0000;
    }
    &::placeholder {
      color: #cc0000;
    }
    &::placeholder {
      color: #cc0000;
    }
    &::placeholder {
      color: #cc0000;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 300 !important;
}

.ng-select {
  &.center-selection {
    .ng-value-container {
      justify-content: center;
    }
  }
  &.capital-selection {
    .ng-value {
      span {
        text-transform: capitalize;
      }
    }
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          text-transform: capitalize;
        }
      }
    }
  }
}

/* Standard syntax */
@keyframes slideuper {
  from {
    bottom: -200px;
  }
  to {
    bottom: 0px;
  }
}

.navbar-expand-lg .navbar-toggler {
  @media screen and (min-width: 767px) and (max-width: 1024px) {
    display: flex !important;
  }
}

.container-fluid {
  @media screen and (min-width: 992px) {
    width: calc(100% - 8%) !important;
  }
}
.fa-angle-up,.fa-angle-down{
  font-family: FontAwesome!important;
}
/*
*********************
use for mandatory * 
icon color on shipment 
details form
*********************
*/
span.required-icon {
  color: #2475e9 !important;
  font-style: normal!important;
  font-size:14px!important;
}
.z-index{
  z-index: -1;
}
// for translation code start here
.translate{
  list-style:none;
  position: relative; 
  translate:0.3s;
  margin-left: 20px;
  padding-top: 7px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
    position: absolute;
    right: 130px;
  }
  @media screen and (max-width: 991px) {    
    right: 90px;
  }
  @media screen and (max-width: 767px) {
    right: 75px;
    padding-top: 5px;
  }
  svg{
    margin-right: 5px;
    @media screen and (max-width: 767px) {
     width: 12px;
    }
  }
  li{
    //background:black;
    min-width: 66px;
    position: relative;
    justify-content: flex-end;
    padding-right: 15px;
    @media screen and (max-width: 767px) {
      min-width: 72px;     
    }
    .arrow-up{
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid white;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translatey(-50%);
      transition: 0.3s;
      @media screen and (max-width: 767px) {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid white;
          
      }
      &.arrow-rotate{
        transform: rotate(180deg);
        top: 40%;
      }
    }

    a{
     justify-content: flex-end;
      text-decoration: none;
      color: white;
      font-size: 16px;
      text-decoration:none;
      font-weight: 400;
      display:flex;
      align-items: center;
      font-weight:500;     
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      // &.inside-para{
      //   padding: 3px 20px;
      //   color: white;
      //   text-decoration: none;
      //   min-height: 30px;
      //   display: inline-flex;  
      // }
    }   
    &.inside-colour{
      background: rgba(248,248,248,0.2);
      padding-right: 0;
      a{
        min-height: 26px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    color: rgba(255,255,255,0.47);
    transition: 0.2;
    &.active{
      color:white;
    }
    &:hover{
      color:white;
    }
      }
      &:hover{
        background: rgba(255,255,255,0.4);
    
      }
      &:nth-child(1){
        border-top: 2px #2475e9 solid;
      }
    }
  }
  &.translate-inside{    
    position: absolute;
    top: 20px;
    right: 0;
    opacity:0;
    visibility:hidden;
    transition:0.3s;
    display: flex;
    flex-direction: column;
    padding-top:10px;
    &.check{
      top: 16px;
      opacity: 1;
      visibility: visible;
      min-width: 90px;
      @media screen and (max-width: 767px) {
        min-width: 72px;
      }
     } 
  }   
&.resultpage-dark-bg{  
  min-width: 90px;
  @media screen and (max-width: 1024px) {
   margin-left: auto;
  }
  @media screen and (max-width: 991px) {
    right: 80px; 
   }
  @media screen and (max-width: 767px) {
    min-width: 72px;
    right: 65px
  }
  svg{
    display: block;
    g{
      fill: black;
    }

  }
  li{
    .arrow-up{
      border-top: 8px solid black;
    }
    a{
      color: black;

    }
    ul{
      min-width: 90px;
      @media screen and (max-width: 767px) {
        min-width: 72px;
      }
      &.translate-inside{
        background: #f8f8f8;
        top: 30px;
        padding-top: 0;
        border-radius: 0;
        border-top: 2px #2475e9 solid;

        .inside-colour{
          background:transparent;
          &:hover{
            background: transparent;
          }
          a{
            min-height: auto;
            color: rgba(0,0,0,0.25);
            &.active{
              color: black;
            }
            &:hover{
              color: black;
            }
          }
          &:nth-child(1){
            border-top: 0;
            margin: 0px 0;
          }
          &:nth-child(2){
            border-top: 0;
            margin-bottom:5px;
          }
          &:hover{
            background:transparent;
          }
        }
        &:before{
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom : 6px solid black;
          position: absolute;
          right: 7px;
          top: -6px;
          content: '';
          display: none;
        }
      }
    }
  }
}


}






  
// for translation code end here
.navbar{
.navbar-toggler{
  .navbar-toggler-icon{
    .new-fixed-bar{}
    .new-fixed-cross{display: none;width: 19px;}
  }
}
&.header-mobile-view{
  .navbar-toggler{
    .navbar-toggler-icon{
      .new-fixed-bar{display: none;}
      .new-fixed-cross{display: block;}
    }
  }
  .translate{
    display: none;
  }
}
}



.new-fixed-cross{
  display: none;
}
// after bootstrap update 5
body{
  app-ngpl-header, app-ngpl-footer{
    .row>* {
      // --bs-gutter-y: 0;
      // padding-right: calc(var(--bs-gutter-y));
      // padding-left: calc(var(--bs-gutter-y));
      width: auto;
    }
  }
  .row>* {
    --bs-gutter-y: 0;
    padding-right: calc(var(--bs-gutter-y));
    padding-left: calc(var(--bs-gutter-y));
  }
  
  // .row {
  //   --bs-gutter-x: -0.9375rem;
  //   margin-right: var(--bs-gutter-x);
  //   margin-left: var(--bs-gutter-x);
  // }
  
  div[class*="col-"]:not(div[class*="form-col-"]) {
    --bs-gutter-x: 0.9375rem;
    padding-right: var(--bs-gutter-x);
    padding-left: var(--bs-gutter-x);
  }
}
ul{
  padding-left: 0;
}
body {
  .table > :not(caption) > * > *{
      background: transparent;
  }
}